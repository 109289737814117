import React, { useState } from 'react';
import 'assets/styles/pages/sidebar.scss';
import { Link, navigate } from 'gatsby';
import treeicon from 'assets/images/admin/tree-icon.png';
import breadcumbarrow from 'assets/images/admin/breadcumb-arrow-left.png';
import { useForm } from "react-hook-form";
import AdminService from 'services/admin/admin.service';
import { Modal, Spinner } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import PlantationListDetails from 'services/plantation/plantation.service';

export default function AdminAddpage() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const adminApi = new AdminService();
  const ds = new PlantationListDetails();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data) => {

    let formData = {
      "id": 0,
      "name": data.name,
      //"firebaseId": data.firebaseId,
      "emailAddress": data.email,
      "status": data.adminStatus,
      "roles": [
        "ADMIN"
      ]
    }
    setLoading(true);
    adminApi.getAddAdmin(formData).then(data => {
      if (data.status === 200) {
        setLoading(false)
        toast.success("Admin added successfully")
        setTimeout(function () {
          navigate('/admin/adminstatus/');
        }, 3000)
      }
    }).catch(error => {
      setLoading(false)
      console.log("else", error.response.data.message)
      toast.error(error.response.data.message);
    })
  };
  return (
    <>
      <div className='sidebar-content-wrapper'>
        <div className='admin-breadcumb-item'>
          <span>
            <img src={treeicon} alt='treeicon' />
          </span>

          <span>
            <img src={breadcumbarrow} alt='arrowicon' />
          </span>

          <Link className='' to='/admin/adminstatus/'>
            {' '}
            Back{' '}
          </Link>
        </div>
        <div className='white-iitiative-wrapper'>
          <div className=''>
            <h3 className='sub-heading-title'>ADD ADMIN USER </h3>
          </div>

          <div className='register-plantation'>
            <div className='container'>
              <div className='row'>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className='row'>
                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Name <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='text'
                          name='name'
                          className='form-control '
                          id='name'
                          placeholder=''
                          {...register("name", {
                            required: "Name is required"
                          })}
                        />
                        {errors.name && (
                          <span className="form-error">
                            {errors.name.message}
                          </span>
                        )}
                      </div>
                    </div>

                    {/* <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='firebaseId' className='form-label'>
                          Firebase Id
                        </label>
                        <input type='text' name='firebaseId' className='form-control ' id='firebaseId' placeholder=''
                          {...register("firebaseId", {
                            required: "Firebase Id is required"
                          })} />
                        {errors.firebaseId && (
                          <span className="form-error">
                            {errors.firebaseId.message}
                          </span>
                        )}
                      </div>
                    </div> */}

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='name' className='form-label'>
                          Email ID <i className="hastric-color">*</i>
                        </label>
                        <input
                          type='email'
                          name='email'
                          className='form-control '
                          id='email'
                          placeholder='Enter your email address'
                          {...register("email", {
                            required: "Enter your Email",
                            pattern: {
                              value:
                                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              message: "Please enter a valid email",
                            },
                          })}
                        />
                        {errors.email && (
                          <span className="form-error">
                            {errors.email.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className='col-md-6'>
                      <div className='fc-wrapper'>
                        <label htmlFor='adminStatus' className='form-label'>
                          Status <i className="hastric-color">*</i>
                        </label>
                        <select name='adminStatus' id='adminStatus' className='form-control' {...register("adminStatus", { required: true })}>
                          <option value='2'>Active</option>
                          <option value='1'> Inactive </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className='text-right'>
                    <Link to='/admin/adminstatus/' className='btn btn-cancel mg-right10 margin-btm-10'>
                      Cancel
                    </Link>

                    <button className='btn btn-viewmore save-width'>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal show={loading} centered className="modal-con">
        <div className="modal-con">
          <Spinner style={{ textAlign: "center" }} animation="border" />
        </div>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        newestOnTop={false}
        hideProgressBar
        closeOnClick
        rtl={false}
      />

    </>
  );
}
